import { defineStore } from "pinia";
import ModerationService from "~/services/ModerationService";
import { simpleApi } from "~/common/API/simpleApi";
import { usePublisherStore } from "./PublisherModule";
import { chatApi } from "~/common/API/chatApi";

export const useModerationStore = defineStore("moderation", () => {
  const pubStore = usePublisherStore();

  const moderateCount = ref<number>(0);
  const moderateOrders = ref<number>(0);

  const savedUsers = ref<any[]>([]);

  const confirmModerate = async (payload: number) => {
    return new Promise((resolve) => {
      ModerationService.confirmModerate(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          resolve(false);
        });
    });
  };

  const rejectModerate = async (payload: {
    rejectId: number;
    reason: string;
    reject?: boolean;
  }) => {
    return new Promise((resolve) => {
      ModerationService.rejectModerate(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          resolve(false);
        });
    });
  };

  const newValuesFromAdmin = async (data: any) => {
    if (data.compare.length === 0 && Object.keys(data.moderate).length === 0) return;

    interface ISaveData {
      id?: number | string;
      categoriesIds?: number[];
      interestsIds?: number[];
      subscribersStatistic?: any;
      viewsStatistic?: any;
    }

    const saveData: ISaveData | any = {
      id: data.newValues.id ? data.newValues.id : data.id,
    };

    data.compare.forEach((item: any) => {
      if (item.type === "categories") {
        saveData["categoriesIds"] = data.newValues["categoriesIds"];
      } else if (item.type === "interests") {
        saveData["interestsIds"] = data.newValues["interestsIds"];
      } else if (item.type === "subs") {
        if (!saveData["subscribersStatistic"]) {
          saveData["subscribersStatistic"] = {};
        }
        saveData["subscribersStatistic"]["count"] = +item.newValue;
      } else if (item.type === "genderF") {
        if (!saveData["subscribersStatistic"]) {
          saveData["subscribersStatistic"] = {};
        }
        saveData["subscribersStatistic"]["femalePercent"] = +item.newValue;
      } else if (item.type === "genderM") {
        if (!saveData["subscribersStatistic"]) {
          saveData["subscribersStatistic"] = {};
        }
        saveData["subscribersStatistic"]["malePercent"] = +item.newValue;
      } else if (item.type === "views") {
        if (!saveData["viewsStatistic"]) {
          saveData["viewsStatistic"] = {};
        }
        saveData["viewsStatistic"]["countLast24h"] = +item.newValue;
      } else {
        saveData[item.type] = data.newValues[item.type];
      }
    });

    if (data.moderate) {
      if (data.moderate.subs) {
        if (!saveData["subscribersStatistic"]) {
          saveData["subscribersStatistic"] = {};
        }
        saveData["subscribersStatistic"]["count"] = +data.moderate.subs;
      }
      if (data.moderate.views) {
        if (!saveData["viewsStatistic"]) {
          saveData["viewsStatistic"] = {};
        }
        saveData["viewsStatistic"]["countLast24h"] = +data.moderate.views;
      }
      if (data.moderate.genderF) {
        if (!saveData["subscribersStatistic"]) {
          saveData["subscribersStatistic"] = {};
        }
        saveData["subscribersStatistic"]["femalePercent"] = +data.moderate.genderF;
      }
      if (data.moderate.genderM) {
        if (!saveData["subscribersStatistic"]) {
          saveData["subscribersStatistic"] = {};
        }
        saveData["subscribersStatistic"]["malePercent"] = +data.moderate.genderM;
      }
    }

    // console.log('saveData', { ...data.newValues, ...saveData});

    return new Promise((resolve) => {
      simpleApi("/place/telegram/update", {
        method: "PUT",
        body: { ...data.newValues, ...saveData },
      }) // Выполняем запрос к API с использованием simpleApi
        .then((res) => {
          resolve(res); // Возвращаем ответ
        })
        .catch((err) => {
          console.error(err); // Логируем ошибку
          resolve(false); // Возвращаем false в случае ошибки
        });
    });
  };

  const getAlerts = async () => {
    const usePublisherStore__roles = localStorage.getItem("usePublisherStore__roles");
    let roles = [];
    if (usePublisherStore__roles) {
      roles = JSON.parse(usePublisherStore__roles);
    }
    if (![4, 2].some((role) => roles.includes(role))) {
      return;
    }
    try {
      const res: any = await ModerationService.getModerationCount({ query: "?status[]=pending" });
      if (res && res.moderationRequestsCount) {
        moderateCount.value =
          res.moderationRequestsCount.find((item: any) => item.status === "pending")?.count || 0;
      }
    } catch (err) {
      console.error(err); // Обработка ошибки, если необходимо
    }
    try {
      const res: any = await ModerationService.getOrdersCounts({ status: "moderation" });
      console.log('res', res);
      if (res && res.ordersCount.length) {
        moderateOrders.value =
          res.ordersCount.find((item: any) => item.status === "moderation")?.count || 0;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getPublishers = async (data: any) => {
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === null || data[key] === "") {
          delete data[key];
          return;
        }
        if (key === "registration" && data[key].length) {
          data["createdAtFrom"] = new Date(data[key][0]).toISOString();
          data["createdAtTo"] = new Date(data[key][1]).toISOString();
          delete data[key];
        }
        if (key === "registration") {
          delete data[key];
        }

        if (key === "loggedIn" && data[key].length) {
          data["loggedInFrom"] = new Date(data[key][0]).toISOString();
          data["loggedInTo"] = new Date(data[key][1]).toISOString();
          delete data[key];
        }
        if (key === "loggedIn") {
          delete data[key];
        }

        if (key === "text" || key === "name") {
          data["contacts"] = data[key];
          // data['telegramId'] = data[key];
          delete data[key];
        }
      });
    }

    if (data.ordersFrom && !data.ordersTo) {
      data["ordersFrom"] = +data.ordersFrom;
      data["ordersTo"] = 10000;
    }

    if (data.ordersTo && !data.ordersFrom) {
      data["ordersFrom"] = 0;
      data["ordersTo"] = +data.ordersTo;
    }

    if (data.ordersFrom && data.ordersTo) {
      data["ordersFrom"] = +data.ordersFrom;
      data["ordersTo"] = +data.ordersTo;
    }

    if (data.balanceFrom && !data.balanceTo) {
      data["balanceFrom"] = +data.balanceFrom;
      data["balanceTo"] = 1000000;
    }

    if (data.balanceTo && !data.balanceFrom) {
      data["balanceFrom"] = 0;
      data["balanceTo"] = +data.balanceTo;
    }

    if (data.balanceFrom && data.balanceTo) {
      data["balanceFrom"] = +data.balanceFrom;
      data["balanceTo"] = +data.balanceTo;
    }

    if (data.publisherChannelsFrom && !data.publisherChannelsTo) {
      data["placesFrom"] = +data.publisherChannelsFrom;
      data["placesTo"] = 1000;
    }

    if (data.publisherChannelsTo && !data.publisherChannelsFrom) {
      data["placesFrom"] = 0;
      data["placesTo"] = +data.publisherChannelsTo;
    }

    if (data.publisherChannelsFrom && data.publisherChannelsTo) {
      data["placesFrom"] = +data.publisherChannelsFrom;
      data["placesTo"] = +data.publisherChannelsTo;
    }

    try {
      const response = await ModerationService.getUsers({
        params: {
          ...data,
          role: 3,
          "sortDirections[]": ["desc"],
          "sortFields[]": ["id"],
        },
      });
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const getAdmins = async () => {
    try {
      return await ModerationService.getUsers({
        params: {
          role: 4,
          "sortDirections[]": "desc",
          "sortFields[]": "id",
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getAdvertisers = async (data: any) => {
    if (data) {
      Object.keys(data).forEach((key) => {
        if (
          data[key] === null ||
          data[key] === "" ||
          (typeof data[key] === "object" && data[key].length === 0)
        ) {
          delete data[key];
          return;
        }
        if (key === "registration" && data[key].length) {
          data["createdAtFrom"] = new Date(data[key][0]).toISOString();
          data["createdAtTo"] = new Date(data[key][1]).toISOString();
          delete data[key];
        }

        if (key === "loggedIn" && data[key].length) {
          data["loggedInFrom"] = new Date(data[key][0]).toISOString();
          data["loggedInTo"] = new Date(data[key][1]).toISOString();
          delete data[key];
        }

        if (key === "text") {
          data["contacts"] = data[key];
          // data['telegramId'] = data[key];
          delete data[key];
        }
      });
    }
    if (data.orders) {
      if (data.orders[0] && !data.orders[1]) {
        data["ordersFrom"] = +data.orders[0];
        data["ordersTo"] = 10000;
      }

      if (data.orders[1] && !data.orders[0]) {
        data["ordersFrom"] = 0;
        data["ordersTo"] = +data.orders[1];
      }

      if (data.orders[0] && data.orders[1]) {
        data["ordersFrom"] = +data.orders[0];
        data["ordersTo"] = +data.orders[1];
      }
    }

    try {
      const response: any = await ModerationService.getUsers({
        params: {
          ...data,
          role: 10,
          "sortDirections[]": "desc",
          "sortFields[]": "id",
        },
      });
      const typedResponse = response as { meta: any };
      return { meta: typedResponse.meta, records: response.records };
    } catch (err) {
      console.error(err);
    }
  };

  const getUsers = async (data: any) => {
    try {
      return await ModerationService.getUsers({
        params: {
          ...data,
          // 'sortDirections[]': 'desc',
          // 'sortFields[]': 'id',
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getChannels = async (data: any, filter: any) => {
    Object.keys(data).forEach((key) => {
      if (data[key] === null || data[key] === "") {
        delete data[key];
      }
    });
    let query = "";

    if (filter.filter === "new") {
      data["status[]"] = "pending";
    }
    if (filter.filter === "notNew") {
      data["status[]"] = ["approved", "rejected", "rework"];
    }
    if (filter.status === "approved") {
      data["status[]"] = ["approved"];
    }
    if (filter.status === "rejected") {
      data["status[]"] = ["rejected", "rework"];
    }
    if (filter.date) {
      data.createdAtFrom = filter.date[0].toISOString();
      data.createdAtTo = filter.date[1].toISOString();
    }
    if (filter.search) {
      data.names = filter.search;
    }

    data["sortDirections[]"] = ["desc"];
    data["sortFields[]"] = ["id"];

    try {
      return await ModerationService.getChannels({
        params: { ...data },
        query,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getAllChannels = async (data: any) => {
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === null || data[key] === "") {
          delete data[key];
          return;
        }
        if (key === "registration") {
          data["createdAtFrom"] = new Date(data[key][0]).toISOString();
          data["createdAtTo"] = new Date(data[key][1]).toISOString();
          delete data[key];
        }

        if (key === "status" && data[key] === "all") {
          delete data[key];
        }

        if (data.ordersFrom && !data.ordersTo) {
          data["ordersFrom"] = +data.ordersFrom;
          data["ordersTo"] = 10000;
        }

        if (data.ordersTo && !data.ordersFrom) {
          data["ordersFrom"] = 0;
          data["ordersTo"] = +data.ordersTo;
        }

        if (data.ordersFrom && data.ordersTo) {
          data["ordersFrom"] = +data.ordersFrom;
          data["ordersTo"] = +data.ordersTo;
        }

        if (key === "text") {
          data["names"] = data[key];
          // data['telegramId'] = data[key];
          delete data[key];
        }
      });
    }

    console.log("FILTER", data);

    try {
      const response: any = await ModerationService.getAllChannels({ params: { ...data } });
      console.log(13, response.meta);
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const getAllOrders = async (data: any) => {
    // Удаляем все пустые параметры
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === null || data[key] === "" || data[key] === undefined) {
          delete data[key];
        }

        // Проверка массивов
        if (Array.isArray(data[key]) && data[key].length === 0) {
          delete data[key];
        }
      });
    }
    if (data.status === "all") {
      delete data.status;
    }
    if (data.status) {
      data["status[]"] = data.status;
      delete data.status;
    }
    if (data.dates) {
      data["createdAtFrom"] = new Date(data.dates[0]).toISOString();
      const endDate = new Date(data.dates[1]);
      endDate.setHours(23, 59, 59);
      data["createdAtTo"] = endDate.toISOString();
      console.log(data.dates);
    }

    if (data["status[]"] === "done") {
      data["status[]"] = ["done", "success"];
    }

    console.log("DATA", data);

    try {
      const response = await ModerationService.getAllOrders({
        params: {
          ...data,
          "sortDirections[]": "desc",
          "sortFields[]": "id",
        },
      });
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const getAllPublicates = async (page: number = 1, filter: any) => {
    const params: any = {
      pageNumber: page,
      "excludeOrderStatus[]": ["draft", "payed", "rejected"],
    };
    const all = {
      "status[]": [
        "publisher-approved",
        "admin-approved",
        "publisher-set-link-timeout",
        "publisher-rejected",
        "publisher-rework",
        "advertiser-timeout",
        "link-timeout",
        "link-rejected",
        "link-provided",
        "cancel",
        "advertiser-link-approved",
        "admin-rejected",
      ],
    };
    if (filter?.filter && filter.filter === "new") {
      params["status[]"] = ["new"];
      params["orderStatus[]"] = ["moderation", "in-progress"];
    }
    if (filter?.filter && filter.filter === "notNew") {
      // params['status[]'] = ['publisher-approved', 'admin-approved', 'publisher-set-link-timeout', 'publisher-rejected', 'publisher-rework', 'advertiser-timeout', 'link-timeout', 'link-rejected', 'link-provided', 'cancel', 'advertiser-link-approved', 'admin-rejected']
      params["excludeStatus[]"] = ["new"];
    }
    if (filter?.status && filter.status === "rejected") {
      params["status[]"] = ["admin-rejected"];
    }
    if (filter?.status && filter.status === "approved") {
      // params['status[]'] = ['publisher-approved', 'admin-approved', 'publisher-set-link-timeout', 'publisher-rejected', 'publisher-rework', 'advertiser-timeout', 'link-timeout', 'link-rejected', 'link-provided', 'cancel', 'advertiser-link-approved'];
      params["excludeStatus[]"] = ["new", "admin-rejected"];
    }

    const response = await ModerationService.getAllPublishes(params);
    console.log("response", response);
    return response;
  };

  const getOrderById = async (id: number) => {
    return await ModerationService.getOrderById(id);
  };

  // const getModerateOrders = async (data: any) => {
  //   try {
  //     const response = await ModerationService.getAllOrders({ params: {...data}, query: '?page[perPage]=100' });
  //     return response;
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

  const deleteModeration = async (data: any) => {
    try {
      return await ModerationService.deleteModeration(data);
    } catch (err) {
      console.error(err);
    }
  };

  const updateAdmin = async (data: any) => {
    try {
      return await ModerationService.updateAdmin(data);
    } catch (err) {
      console.error(err);
    }
  };

  const updatePublisher = async (data: {
    id: number;
    name?: string;
    telegramId?: string;
    email?: string;
  }) => {
    try {
      const response = await ModerationService.updatePublisher(data);
      if (response) {
        ElNotification({
          title: "Успешное обновление",
          message: "Спасибо!",
          type: "success",
        });
      }
      console.log(response);
    } catch (err) {
      console.log(err);
      ElNotification({
        title: "Ошибка обновления",
        message: "попробуйте чуть позже или свяжитесь с администратором...",
        type: "warning",
      });
    }
  };

  const publicationReject = async (data: { reason?: string; id: number }) => {
    try {
      const response = await ModerationService.publicationReject(data);
      if (response) {
        ElNotification({
          title: "Успешное обновление",
          message: "Спасибо!",
          type: "success",
        });
      }
      return response;
    } catch (err) {
      console.log(err);
      ElNotification({
        title: "Ошибка обновления",
        message: "попробуйте чуть позже или свяжитесь с администратором...",
        type: "warning",
      });
    }
  };

  const publicationApprove = async (data: { id: number }) => {
    try {
      const method = "PUT";
      const endpoint = "/advert/publication/admin-approve";
      const response = await ModerationService.universalApi(data, endpoint, method);
      if (response) {
        ElNotification({
          title: "Успешное обновление",
          message: "Спасибо!",
          type: "success",
        });
      }
      return response;
    } catch (err) {
      console.log(err);
      ElNotification({
        title: "Ошибка обновления",
        message: "попробуйте чуть позже или свяжитесь с администратором...",
        type: "warning",
      });
    }
  };

  interface ISaveData {
    id: number;
    telegram?: string;
    email?: string;
    name?: string;
  }

  const updateUser = async (data: {
    id: number;
    name?: string;
    telegramId?: string;
    email?: string;
    newPassword?: string;
  }) => {
    try {
      if (data.newPassword && data.newPassword.length > 0) {
        await ModerationService.setPassword({
          id: data.id,
          password: data.newPassword,
        });
      }
      const response = await ModerationService.updatePublisher(data);
      if (response) {
        ElNotification({
          title: "Успешное обновление",
          message: "Спасибо!",
          type: "success",
        });
      }
      console.log(response);
    } catch (err) {
      console.log(err);
      ElNotification({
        title: "Ошибка обновления",
        message: "попробуйте чуть позже или свяжитесь с администратором...",
        type: "warning",
      });
    }
  };

  getAlerts();

  // CHAT
  const startChat = async (data: {
    publicationId: number;
    publisherId: number;
    status: string;
  }) => {
    const response = await chatApi("/api/v1/chat");
    console.log("CHAT", data, response);
    return response;
  };

  return {
    savedUsers,
    moderateCount,
    moderateOrders,
    confirmModerate,
    rejectModerate,
    newValuesFromAdmin,
    getAlerts,
    getAdmins,
    getPublishers,
    getAdvertisers,
    getUsers,
    getChannels,
    getAllChannels,
    getAllOrders,
    deleteModeration,
    updateAdmin,
    updatePublisher,
    publicationReject,
    publicationApprove,
    updateUser,
    getAllPublicates,
    getOrderById,
    startChat,
  };
});
