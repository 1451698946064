import { simpleApi } from "~/common/API/simpleApi";
import { ElNotification } from "element-plus";

interface IGetChannelsParams {
  entityType?: string;
  status?: string[];
  purpose?: string[];
  userId?: number;
  createdAtFrom?: string;
  createdAtTo?: string;
  pageNumber?: number;
  pageSize?: number;
  perPage?: number;
  "sortDirections[]"?: string;
  "sortFields[]"?: string;
  role?: string | number | string[];
  "status[]"?: string[];
  "entityId[]"?: number[];
}

interface IUniversalPlayloads extends IGetChannelsParams {
  id?: number;
  name?: string;
  telegramId?: string;
  email?: string;
  reason?: string;
}

interface IGetOrdersCounts {
  status?: string;
  publicationsStatus?: string[];
  createdAtFrom?: string;
  createdAtTo?: string;
}

interface ModerationResponse {
  success: boolean;
  message: string;
  data?: any;
}

type RequestMethods = "GET" | "POST" | "PUT" | "DELETE";

interface ApiCallOptions {
  method?: RequestMethods;
  body?: any;
  params?: any;
  query?: string;
}

// 1. Создать общий тип для ответа API
type ApiResponse<T> = {
  data: T;
  error?: string;
  success: boolean;
}

// 2. Создать общий обработчик ошибок
const handleApiError = (error: unknown, message: string) => {
  console.error(error);
  ElNotification({
    title: "Ошибка",
    message: message,
    type: "error",
  });
  return null;
}

// 3. Создать обертку для API-вызовов
const apiCall = async <T>(
  endpoint: string,
  options: ApiCallOptions
): Promise<ApiResponse<T> | null> => {
  try {
    const response = await simpleApi(endpoint, options) as T;
    return { data: response, success: true };
  } catch (error) {
    return handleApiError(error, "Попробуйте позже или свяжитесь с администратором");
  }
}

const ENDPOINTS = {
  MODERATION: {
    SEARCH: '/moderation/search',
    APPROVE: '/moderation/approve',
    REJECT: '/moderation/reject',
    REWORK: '/moderation/rework',
    DELETE: '/moderation/delete',
    COUNT: '/moderation/count'
  },
  ADMIN: {
    USERS: '/admin/users',
    ORDERS: '/admin/orders',
    // ... другие эндпоинты
  }
} as const;

export default class ModerationService {
  static getChannels = async (data: { params?: IGetChannelsParams; query?: string } = {}) => {
    const { params, query } = data;
    return apiCall(ENDPOINTS.MODERATION.SEARCH, {
      params: {
        ...params,
        ...(query ? { query } : {})
      }
    });
  };

  /**
   * Подтверждает модерацию канала
   * @param channelId - ID канала для модерации
   * @returns Promise с результатом модерации
   */
  static confirmModerate = async (channelId: number): Promise<ApiResponse<ModerationResponse> | null> => {
    if (!channelId) {
      return handleApiError(null, "Не указан ID канала");
    }

    const response = await apiCall<ModerationResponse>(ENDPOINTS.MODERATION.APPROVE, {
      method: 'PUT',
      body: { id: channelId }
    });

    if (response?.success) {
      ElNotification({
        title: "Успешная модерация",
        message: "Канал успешно прошел модерацию",
        type: "success",
      });
    }

    return response;
  };

  // Метод для отклонения модерации канала
  static rejectModerate = async (payload: {
    rejectId: number;
    reason: string;
    reject?: boolean;
  }) => {
    // Определяем конечную точку API в зависимости от значения reject
    const endpoint = payload.reject ? "/moderation/reject" : "/moderation/rework";
    console.log("payload", payload);
    return new Promise((resolve) => {
      // Выполняем запрос к API с использованием simpleApi
      simpleApi(endpoint, { method: "PUT", body: { id: payload.rejectId, reason: payload.reason } })
        .then((res) => {
          // Уведомление об успешном отклонении модерации
          ElNotification({
            title: "Успешное отклонение",
            message: "Модерация отклонена",
            type: "success",
          });
          resolve(res); // Возвращаем ответ
        })
        .catch((err) => {
          console.error(err); // Логируем ошибку
          resolve(false); // Возвращаем false в случае ошибки
          // Уведомление об ошибке при отклонении модерации
          ElNotification({
            title: "Ошибка модерирования канала",
            message: "попробуйте чуть позже или свяжитесь с администратором...",
            type: "error",
          });
        });
    });
  };

  // Метод для получения количества запросов на модерацию
  static getModerationCount = async (data: { query?: string } = {}) => {
    const query = data.query || ""; // Получаем строку запроса или устанавливаем пустую строку, если она не задана
    const endpoint = `/moderation/count`; // Устанавливаем конечную точку API
    return new Promise((resolve) => {
      simpleApi(endpoint, { query: data.query }) // Выполняем запрос к API с использованием simpleApi
        .then((res) => {
          resolve(res); // Возвращаем ответ
        })
        .catch((err) => {
          console.error(err); // Логируем ошибку
          resolve(false); // Возвращаем false в случае ошибки
        });
    });
  };

  static getPublishers = async (data: { params?: IGetChannelsParams }) => {
    const endpoint = "/admin/users";
    return new Promise((resolve) => {
      simpleApi(endpoint, data)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          resolve([]);
        });
    });
  };

  static getAdvertisers = async (data: { params?: IGetChannelsParams }) => {
    // const endpoint = '/admin/user/advertisers?sortDirections[]=desc&sortFields[]=id';
    const endpoint = "/admin/users";
    return new Promise((resolve) => {
      simpleApi(endpoint, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          resolve([]);
        });
    });
  };

  static getUsers = async (data: { params?: IGetChannelsParams }) => {
    try {
      const endpoint = ENDPOINTS.ADMIN.USERS;
      return await simpleApi(endpoint, data);
    } catch (error) {
      console.error("Ошибка при получении пользователей:", error);
      return [];
    }
  };

  static getAllChannels = async (data: { params?: IGetChannelsParams }) => {
    const emdpoint = "/place/telegram/search?sortDirections[]=desc&sortFields[]=id";
    return new Promise((resolve) => {
      simpleApi(emdpoint, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          resolve([]);
        });
    });
  };

  static getAllOrders = async (data: { params?: IGetChannelsParams }) => {
    const endpoint = `/admin/orders`;
    return new Promise((resolve) => {
      simpleApi(endpoint, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          resolve([]);
        });
    });
  };

  static deleteModeration = async (payload: number) => {
    if (!payload) {
      ElNotification({
        title: "Ошибка удаления канала",
        message: "попробуйте чуть позже или свяжитесь с администратором...",
        type: "error",
      });
      return;
    }
    const endpoint = "/moderation/delete";
    return new Promise((resolve) => {
      simpleApi(endpoint, { method: "DELETE", body: { id: payload } })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          resolve([]);
        });
    });
  };

  static updateAdmin = async (payload: {
    id: number;
    role: number[];
    name: string;
    email: string;
    telegram: string;
  }) => {
    const endpoint = "/admin/user/advertiser/update";
    return new Promise((resolve) => {
      simpleApi(endpoint, { method: "PUT", body: payload })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          resolve([]);
        });
    });
  };

  static updatePublisher = async (payload: {
    id: number;
    name?: string;
    telegramId?: string;
    email?: string;
  }) => {
    const endpoint = "/admin/user/publisher/update";
    try {
      const response = await simpleApi(endpoint, { method: "PUT", body: payload });
      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  static setPassword = async (payload: { id: number; password: string }) => {
    const endpoint = "/admin/user/set-password";
    try {
      return await simpleApi(endpoint, {
        method: "POST",
        body: payload,
      });
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  static publicationReject = async (payload: { reason?: string; id: number }) => {
    const endpoint = "/advert/publication/admin-reject";
    try {
      const response = await simpleApi(endpoint, { method: "PUT", body: payload });
      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      console.log("Error 1", error);
      return null;
    }
  };

  static universalApi = async (
    payload: IUniversalPlayloads,
    endpoint: string,
    method: RequestMethods
  ) => {
    try {
      const response = await simpleApi(endpoint, { method: method, body: payload });
      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      console.log("Error 1", error);
      return null;
    }
  };

  static getOrdersCounts = async (params: {
    status?: string;
  }): Promise<
    | {
      ordersCount: {
        status: string;
        count: number;
      }[];
    }
    | unknown
  > => {
    const endpoint = `/admin/order/count`;
    try {
      const response = await simpleApi(endpoint, { params });
      console.log("response", response);
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  static getAllPublishes = async (playload?: IGetChannelsParams) => {
    const endpoint = "/advert/publications";
    const params = {
      ...playload,
      "sortDirections[]": "desc",
      "sortFields[]": "id",
    };
    try {
      const response = await simpleApi(endpoint, { params });
      console.log("response", response);
      return response;
    } catch (error) {
      console.error(error);
      throw new Error("Ошибка при получении всех публикаций");
    }
  };

  static getOrderById = async (id: number) => {
    const endpoint = `/admin/order`;
    const params = {
      id: id,
    };
    try {
      return await simpleApi(endpoint, { params });
    } catch (error) {
      console.error(error);
      return null;
    }
  };
}
